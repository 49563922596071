<script setup>
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import store from '@/store';

const route = useRoute();
const router = useRouter();

onMounted(async () => {
    const tempKey = route.query.tempKey;
    if (tempKey) {
        try {
             await store.dispatch('getAuthTokenWithTempKey', { tempKey });

        } catch (error) {
            console.error('Error during SSOPrivate:', error);
        } finally{
            router.push({name:"dashboard"}); 
        }
    }

});
</script>

<template>
    <div> Espera mientras es redirigido a la suite. </div>
</template>
<style scoped></style>